export const joinURL = (...args: string[]) => {
	return args
		.join("/")
		.replace(/[/]+/g, "/")
		.replace(/^(.+):\//, "$1://")
		.replace(/^file:/, "file:/")
		.replace(/\/(\?|&|#[^!])/g, "$1")
		.replace(/\?/g, "&")
		.replace("&", "?");
};

export const getCurrentUrlWithoutQueryParams = () => {
	return window.location.href.split("?")[0].replace(/^\/+|\/+$/g, "");
};

export function trimSlashes(str: string, fromStart = true, fromEnd = true): string {
	if (!str || typeof str !== "string") {
		return str;
	}

	let start = 0;
	let end = str.length;

	if (fromStart === true) {
		while (str[start] === "/") {
			start++;
		}
	}

	if (fromEnd === true) {
		while (str[end - 1] === "/" && end > 0) {
			end--;
		}
	}

	return str.slice(start, end);
}
